import styled from "@emotion/styled";
import Diego from "../../Assets/Images/Diego.webp";
import Sandra from "../../Assets/Images/Sandra.webp";
import Daniela from "../../Assets/Images/Daniela.webp";

import * as Color from "../../Theme";

export default function AboutOurTeamAbout() {
    return (
        <ContainerAboutMe>
            <TitleAboutMe>Nuestro equipo</TitleAboutMe>
            <SubContainerAboutMe>
                <ContainerIndividual>
                    <ImageMe src={Diego}></ImageMe>
                    <SubContainerIndividual>
                        <TitleContainerIndividual>
                            Diego Alejandro Diaz
                        </TitleContainerIndividual>
                        <Paragraph>
                            Estudiante de Ingeniería de Software, participo en
                            proyectos nacionales como Innovate EPM Emprendiendo
                            y RedCOLSI, asistió a capacitación del conocimiento
                            internacional en la Universidad de Cantabria -
                            España, becado por el ICETEX y la Universidad
                            Cooperativa de Colombia 2023-1, intercambio
                            internacional en la Universidad Andres Bello - Chile
                            becado por la Universidad Cooperativa de Colombia
                            2023-2, experiencia en investigación por el
                            semillero Science, Technology, Innovation and
                            Culture Network - BERSTIC.
                        </Paragraph>
                    </SubContainerIndividual>
                </ContainerIndividual>

                <ContainerIndividual>
                    <ImageMe src={Sandra}></ImageMe>
                    <SubContainerIndividual>
                        <TitleContainerIndividual>
                            Sandra Liliana Ramos
                        </TitleContainerIndividual>
                        <Paragraph>
                            Especialista en Control Interno y Aseguramiento.
                            Especialista en Gestión de Activos. Magister en
                            Sistemas Integrados de Gestión. Auditora en las
                            normas ISO 55001 Sistema de Gestión de Activos,
                            Gestión de Calidad ISO 9001-2015, Medio Ambiente ISO
                            14001-2015, Seguridad y Salud en el Trabajo (ISO
                            45001-RUC), Seguridad Vial (ISO 39001),
                            Sostenibilidad Corporativa (ISO 26000) Sistemas de
                            Control Interno (MECI) y MIPG. Más de 10 años de
                            experiencia, como consultora en sistemas de gestión
                            para el logro de certificaciones de diversas normas
                            internacionales. Líder, responsable de dar
                            cumplimiento a todas las etapas de la propuesta.
                            Conocimientos y experiencia en procesos de
                            implementación de normas internacionales, procesos
                            de gestión de riesgos y demás requisitos normativos
                            y legales.
                        </Paragraph>
                    </SubContainerIndividual>
                </ContainerIndividual>
                <ContainerIndividual>
                    <ImageMe src={Daniela}></ImageMe>
                    <SubContainerIndividual>
                        <TitleContainerIndividual>
                            Leidy Daniela Diaz
                        </TitleContainerIndividual>
                        <Paragraph>
                            Auditora en las normas, Gestión de Calidad ISO
                            9001-2015, Medio Ambiente ISO 14001-2015, Seguridad
                            y Salud en el Trabajo (ISO 45001-RUC) y Seguridad
                            Vial (ISO 39001). Consultora y auditora para el
                            diseño, implementación y verificación de Planes
                            Estratégicos, Tácticos y Operativos. Candidata a la
                            especialización en Derecho Laboral y Relaciones
                            Industriales. Más de 3 años de experiencia en
                            Sistemas Integrados de Gestión incluyendo Seguridad
                            y Salud en el Trabajo y Seguridad vial Consultor de
                            apoyo para la ejecución de todas las etapas del
                            proyecto. Conocimientos y experiencia en procesos de
                            gestión de riesgos y normas ISO 9001,
                            45001, 39001 y 14001.
                        </Paragraph>
                    </SubContainerIndividual>
                </ContainerIndividual>
            </SubContainerAboutMe>
        </ContainerAboutMe>
    );
}

const ContainerAboutMe = styled.main`
    display: flex;
    gap: 10vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;

    @media (max-width: 600px) {
        padding: 20% 0;
    }

    @media (max-width: 400px) {
        gap: 2vh;
    }
`;

const TitleAboutMe = styled.h1`
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 600px) {
        font-size: 10vw;
    }

    @media (max-width: 500px) {
        font-size: 11vw;
    }

    @media (max-width: 400px) {
        font-size: 13vw;
    }
`;

const SubContainerAboutMe = styled.main`
    display: flex;
    width: 80%;
    gap: 5vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

const ContainerIndividual = styled.div`
    display: flex;
    justify-content: center;
    gap: 3%;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        gap: 5vh;
    }

    @media (max-width: 400px) {
        gap: 3vh;
    }
`;

const SubContainerIndividual = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 1vh;

    @media (max-width: 700px) {
        width: 90%;
        justify-content: center;
        align-items: center;
    }
`;

const TitleContainerIndividual = styled.h1`
    font-size: 30px;
    font-weight: 800;
    color: ${Color.textDark};
    margin: 0;

    @media (max-width: 750px) {
        font-size: 25px;
    }

    @media (max-width: 700px) {
        text-align: center;
    }

    @media (max-width: 600px) {
        font-size: 6vw;
    }

    @media (max-width: 500px) {
        font-size: 7vw;
    }

    @media (max-width: 400px) {
        font-size: 8vw;
    }
`;

const Paragraph = styled.p`
    font-size: 18px;
    color: ${Color.textDark};
    line-height: 1.9rem;
    margin: 0;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 3vw;
    }

    @media (max-width: 400px) {
        font-size: 4vw;
    }
`;

const ImageMe = styled.img`
    width: 100%;
    max-width: 300px;

    @media (max-width: 800px) {
        max-width: 400px;
    }

    @media (max-width: 600px) {
        max-width: 70%;
    }
`;
