export const primaryColor = "#f6f6f6";
export const secondaryColor = "#fff";
export const tertiaryColor = "#212F3D";
export const quaternaryColor  = "#f4db47"; 
export const fifthColor = "#00182d ";
export const sixthcolor = "#ECF0F1";
export const linkColor = "#020202";
export const linkColorHover = " #64646d";
export const ButtonBlack = " #1e293b";
export const textDark = "#1a1a1a";
export const textWhite = "#f1f1f1";

