import * as Color from "../../Theme";
import imageDocumentsAbout from "../../Assets/Icons/PDFIcon.png";
import styled from "@emotion/styled";

const DocumentsAbout = () => {
    return (
        <DocumentsAboutSection>
            <SubContainerDocumentsAboutAboutSection>
                <TitleDocumentsAbout>Nuestros documentos</TitleDocumentsAbout>
                <ContainerDocumentsAbout>
                    <ContainerIndividual>
                        <DocumentsAboutAboutImage
                            src={imageDocumentsAbout}
                            alt="DocumentsAboutAbout Image"
                        />
                        <ButtonDowloand
                            href={require("../../Assets/Documents/PortafolioDeServiciosSYRD.pdf")}
                            download="PortafolioDeServicios.pdf"
                        >
                            Portafolio de servicios
                        </ButtonDowloand>
                    </ContainerIndividual>
                </ContainerDocumentsAbout>
            </SubContainerDocumentsAboutAboutSection>
        </DocumentsAboutSection>
    );
};

export default DocumentsAbout;

const DocumentsAboutSection = styled.section`
    display: flex;
    padding: 5% 0;
    justify-content: center;
    background-color: ${Color.fifthColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerDocumentsAboutAboutSection = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5%;

    @media (max-width: 1250px) {
        align-items: center;
        flex-direction: column;
        gap: 5vh;
    }
`;

const TitleDocumentsAbout = styled.h1`
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const ContainerDocumentsAbout = styled.div`
    display: flex;
    gap: 2vw;

    @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: repeat(1, 80vw);
    }
`;

const ContainerIndividual = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const DocumentsAboutAboutImage = styled.img`
    width: 100%;
    max-width: 350px;
    border-radius: 10%;

    @media (max-width: 1500px) {
        max-width: 350px;
    }

    @media (max-width: 1300px) {
        max-width: 300px;
    }

    @media (max-width: 1250px) {
        max-width: 350px;
    }

    @media (max-width: 750px) {
        max-width: 250px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

const ButtonDowloand = styled.a`
    font-size: 20px;
    border-radius: 5%;
    padding: 3% 5%;
    background: ${Color.ButtonBlack};
    color: ${Color.textWhite};
    text-decoration: none;
    transition: background-color 0.3s ease;

    @media (max-width: 1250px) {
        font-size: 15px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }

    &:hover {
        background: ${Color.primaryColor};
        color: ${Color.textDark};
    }
`;
