import styled from "@emotion/styled";
import Diego from "../../Assets/Images/Diego.webp";
import Sandra from "../../Assets/Images/Sandra.webp";
import Daniela from "../../Assets/Images/Daniela.webp";
import IconLinkedin from "../../Assets/Icons/Linkedin.svg";
import * as Color from "../../Theme";

function OurTeamIndexIndex() {
    return (
        <OurTeamIndexSection>
            <SubContainerOurTeamIndex>
                <TitleOurTeamIndex>Nuestro equipo directivo</TitleOurTeamIndex>
                <ContainerTeam>
                    <MemberContainer>
                        <MemberImage
                            src={Diego}
                            className="memberImage"
                            alt="Diego Alejandro"
                        />
                        <Overlay className="overlay">
                            <MemberName>Diego Alejandro Diaz</MemberName>
                            <MemberRole>Gerente general</MemberRole>
                            <a href="https://www.linkedin.com/in/diego-d%C3%ADaz-8257a5213?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                                <ImageIcon src={IconLinkedin} />
                            </a>
                        </Overlay>
                    </MemberContainer>

                    <MemberContainer>
                        <MemberImage
                            src={Sandra}
                            className="memberImage"
                            alt="Sandra Liliana"
                        />
                        <Overlay className="overlay">
                            <MemberName>Sandra Liliana Ramos</MemberName>
                            <MemberRole>Directora de proyectos</MemberRole>
                            <a href="https://www.linkedin.com/in/sandra-liliana-ramos-pe%C3%B1aloza-3556a1106?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                                <ImageIcon src={IconLinkedin} />
                            </a>
                        </Overlay>
                    </MemberContainer>

                    <MemberContainer>
                        <MemberImage
                            src={Daniela}
                            className="memberImage"
                            alt="Leidy Daniela"
                        />
                        <Overlay className="overlay ">
                            <MemberName>Leidy Daniela Diaz</MemberName>
                            <MemberRole>
                                Directora en asuntos legales
                            </MemberRole>
                            <a href="https://www.linkedin.com/in/leidy-daniela-d%C3%ADaz-ramos-3147112b1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                                <ImageIcon src={IconLinkedin} />
                            </a>
                        </Overlay>
                    </MemberContainer>
                </ContainerTeam>
            </SubContainerOurTeamIndex>
        </OurTeamIndexSection>
    );
}

export default OurTeamIndexIndex;

const OurTeamIndexSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 5%;
    background: ${Color.secondaryColor};
`;

const SubContainerOurTeamIndex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    gap: 10vh;

    @media (max-width: 1000px) {
        gap: 2vh;
    }
`;

const ContainerTeam = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 5vh;

    @media (max-width: 1250px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1000px) {
        gap: 2vh;
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const TitleOurTeamIndex = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const MemberContainer = styled.div`
    position: relative;
    transition: background 0.5s ease;
    text-align: center;
    justify-content: center;
    border-radius: 5%;

    &:hover {
        background: ${Color.tertiaryColor};
    }

    &:hover .memberImage {
        opacity: 0.2;
    }

    &:hover .overlay {
        opacity: 1;
    }

    @media (max-width: 600px) {
        max-width: 60%;
    }
`;

const MemberImage = styled.img`
    width: 100%;
    max-width: 350px;
    max-height: 400px;
    border-radius: 5%;

    @media (max-width: 1500px) {
        max-width: 300px;
    }

    @media (max-width: 1300px) {
        max-width: 350px;
    }

    @media (max-width: 1250px) {
        max-width: 280px;
    }

    @media (max-width: 750px) {
        max-width: 250px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

const Overlay = styled.div`
    transition: all 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 85%;
`;

const MemberName = styled.h1`
    font-size: 22px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const MemberRole = styled.p`
    font-size: 18px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 20px;
    }

    @media (max-width: 1000px) {
        font-size: 15px;
    }

    @media (max-width: 750px) {
        font-size: 10px;
    }

    @media (max-width: 600px) {
        font-size: 3vw;
    }

    @media (max-width: 400px) {
        font-size: 4vw;
    }
`;

const ImageIcon = styled.img`
    width: 100%;
    max-width: 60px;
    border-radius: 5%;

    @media (max-width: 600px) {
        max-width: 30%;
    }
`;
