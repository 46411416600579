import { Navbar, Nav } from "react-bootstrap";
import styled from "@emotion/styled";
import * as Color from "../../Theme";
import { useState } from "react";
import logo from "../../Assets/Icons/LogoSYRD.png";

function HeaderComponent() {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <NavSection>
            <Navbar expand="lg" className="container">
                <LogoImage src={logo} alt="Company Logo" />
                <ToggleIcon
                    aria-controls="basic-navbar-nav"
                    onClick={handleClick}
                />
                <NavbarCollapseStyles
                    id="basic-navbar-nav"
                    className={`${isOpen ? "show" : ""}`}
                >
                    <NavigationLinks>
                        <NavLink href="/">Inicio</NavLink>
                        <NavLink href="/services">Servicios</NavLink>
                        <NavLink href="/about">Nosotros</NavLink>
                    </NavigationLinks>
                </NavbarCollapseStyles>
            </Navbar>
        </NavSection>
    );
}

export default HeaderComponent;

const NavSection = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Color.primaryColor};
    padding: 1%;
`;

const LogoImage = styled.img`
    width: 100%;
    max-width: 300px;

    @media (max-width: 1300px) {
        max-width: 280px;
    }

    @media (max-width: 1250px) {
        max-width: 250px;
    }

    @media (max-width: 750px) {
        max-width: 200px;
    }

    @media (max-width: 600px) {
        max-width: 50%;
    }
`;

const ToggleIcon = styled(Navbar.Toggle)`
    border: none;

    @media (max-width: 300px) {
        font-size: 5vw;
        padding: 0.5vh 2vw;
        border: none;
    }
`;

const NavbarCollapseStyles = styled(Navbar.Collapse)`
    justify-content: flex-end;
`;

const NavigationLinks = styled(Nav)`
    display: flex;
`;

const NavLink = styled(Nav.Link)`
    transition: border-bottom 0.5s ease;
    font-size: 20px !important;
    padding: 2%;
    transition: border-color 0.3s;
    border: 2px solid transparent;
    text-decoration-line: none;
    color: ${Color.linkColor};

    &:hover {
        color: ${Color.linkColorHover};
    }

    @media (max-width: 400px) {
        font-size: 5vw !important;
    }
`;