import NavBar from "../../Componets/NavBar";
import About from "../../Componets/About";
import MisionAndVision from "../../Componets/MisionAndVision";
import OurHistoryAbout from "../../Componets/OurHistoryAbout";
import ClientsAbout from "../../Componets/ClientsAbout";
import DocumentsAbout from "../../Componets/DocumentsAbout";
import Contact from "../../Componets/Contact";
import Footer from "../../Componets/Footer";
import AboutOurTeamAbout from "../../Componets/OurTeamAbout";
import OurPoliticsAbout from "../../Componets/OurPoliticsAbout";

function AboutPage() {
    return (
        <main>
                <NavBar />
                <About />
                <MisionAndVision />
                <OurPoliticsAbout />
                <OurHistoryAbout />
                <ClientsAbout />
                <AboutOurTeamAbout />
                <DocumentsAbout />
                <Contact />
                <Footer /> 
        </main>
    );
}

export default AboutPage;
