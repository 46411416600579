import styled from "@emotion/styled";
import * as Color from "../../Theme";

function OurHistoryAbout() {
    return (
        <OurHistoryAboutGallerySection>
            <SubContainerOurHistoryAboutSection>
                <FirstContainer>
                    <TitleFistContainer>Nuestra historia</TitleFistContainer>
                    <DescriptionFirstContainer>
                        SYRD SAS, fundada en marzo de 2013, se especializa en la
                        implementación de normas internacionales ISO para
                        empresas. Desde su inicio, ha liderado la certificación
                        en normas como OHSAS 18001, destacando hitos como la
                        certificación de empresas como TRANSPORTES RAPIENTREGA y
                        TRANSPORTADORA LA CEIBA SAS. <br /><br />  Con el tiempo, SYRD SAS ha
                        diversificado sus servicios a nivel nacional,
                        extendiendo a diversos sectores como salud, educación,
                        servicios públicos de gas y energía, así como el
                        transporte de mercancías peligrosas. La empresa se
                        distingue por su compromiso con la excelencia y la
                        calidad en la implementación de normas como ISO 9001,
                        14001, 45001, 39001, 27001 y 55001, trabajando en
                        estrecha colaboración con sus clientes para alcanzar sus
                        objetivos establecidos.
                    </DescriptionFirstContainer>
                </FirstContainer>
                <SecondContainer>
                    <TitleSecondContainer>
                        Hitos importantes
                    </TitleSecondContainer>
                    <ContainerIndividual>
                        <TitleMomentImportant>Fundación</TitleMomentImportant>
                        <YearMoment>2013</YearMoment>
                    </ContainerIndividual>
                    <ContainerIndividual>
                        <TitleMomentImportant>
                            Primer cliente
                        </TitleMomentImportant>
                        <YearMoment>2013</YearMoment>
                    </ContainerIndividual>
                    <ContainerIndividual>
                        <TitleMomentImportant>
                            Primera empresa <br></br>certificada
                        </TitleMomentImportant>
                        <YearMoment>2014</YearMoment>
                    </ContainerIndividual>
                </SecondContainer>
            </SubContainerOurHistoryAboutSection>
        </OurHistoryAboutGallerySection>
    );
}

export default OurHistoryAbout;

const OurHistoryAboutGallerySection = styled.section`
    display: flex;
    padding: 5% 0;
    justify-content: center;
    background-color: ${Color.fifthColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerOurHistoryAboutSection = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;

    @media (max-width: 1250px) {
        align-items: center;
        flex-direction: column;
        gap: 5vh;
    }
`;

const FirstContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 1250px) {
        width: 100%;
        padding: 0 5%;
    }
`;

const TitleFistContainer = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const DescriptionFirstContainer = styled.p`
    font-size: 18px;
    color: ${Color.textWhite};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
        line-height: 4vh;
    }
`;

const SecondContainer = styled.section`
    display: flex;
    flex-direction: column;

    @media (max-width: 500px) {
        width: 90%;
    }
`;

const TitleSecondContainer = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const ContainerIndividual = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleMomentImportant = styled.h2`
    font-size: 30px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const YearMoment = styled.h3`
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;
