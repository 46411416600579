import NavBar from "../../Componets/NavBar";
import TreatmentData from "../../Componets/TreatmentData";

import Footer from "../../Componets/Footer";

function TreatmentDataPage() {
    return (
        <main>
            <NavBar />
            <TreatmentData />
            <Footer />
        </main>
    );
}

export default TreatmentDataPage;
