import Contact from "../../Componets/Contact";
import Footer from "../../Componets/Footer";
import NavBar from "../../Componets/NavBar";
import ServicesGalleryServices from "../../Componets/ServicesGalleryServices";

function ServicePage() {
    return (
        <main>
            <NavBar />
            <ServicesGalleryServices />
            <Contact />
            <Footer />
        </main>
    );
}

export default ServicePage;