    import styled from "@emotion/styled";
import * as Color from "../../Theme";

function Footer () {
    return (
        <FooterContainer>
            <SubContainerFooter>
                <TitleFooter>
                    SYRD Consultorías
                </TitleFooter>
                <IconsContainer>
                    <IconLink
                        href="gerenciadeproyectos@syrd.com.co"
                        target="_blank"
                    >
                        <i className="bi bi-envelope-fill"></i>
                    </IconLink>
                    <IconLink
                        href="https://www.facebook.com/profile.php?id=100054416340121"
                        target="_blank"
                    >
                        <i className="bi bi-facebook"></i>
                    </IconLink>

                    <IconLink
                        href="https://www.facebook.com/profile.php?id=100054416340121"
                        target="_blank"
                    >
                        <i className="bi bi-whatsapp"></i>
                    </IconLink>
                </IconsContainer>
                <CopyrightText>
                    &copy; {new Date().getFullYear()} SYRD Consultorías. All Rights Reserved.
                </CopyrightText>
            </SubContainerFooter>
        </FooterContainer>
    );
};

export default Footer;

const FooterContainer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
    background: ${Color.tertiaryColor};
    font-family: "Quicksand", sans-serif;
`;

const SubContainerFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleFooter = styled.h1`
    font-size: 50px;
    text-align: center;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const IconsContainer = styled.div`
    display: flex;
    margin: 1% 0;
`;

const IconLink = styled.a`
    color: ${Color.textWhite};
    font-size: 35px;
    margin: 0 10px;

    @media (max-width: 400px) {
        font-size: 10vw;
    }
`;

const CopyrightText = styled.p`
    font-size: 30px;
    text-align: center;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;
