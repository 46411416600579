import styled from "@emotion/styled";
import * as Color from "../../Theme";

export default function ourPoliticsAbout() {
    return (
        <PoliticsAboutSection>
            <SubContainerPoliticsAboutSection>
                <TitlePoliticsAbout>Politica integral</TitlePoliticsAbout>
                <DescriptionPoliticsAbout>
                    Nuestra política está orientada a satisfacer las necesidades
                    y expectativas de nuestros clientes, mediante la prestación
                    de servicios profesionales especializados.
                    <br></br>
                    <br></br>
                    Nuestro propósito es crear cultura de sostenibilidad y
                    responsabilidad social empresarial, las cuales serán
                    mejorados continuamente a través de la revisión y adecuación
                    continua de los procesos y procedimientos cumpliendo con los
                    requisitos de las normas y las leyes vigentes.
                </DescriptionPoliticsAbout>

                <LineDivider />

                <TitleLists>Objetivos empresariales</TitleLists>
                <List>
                    <TextList>
                        Eficacia y eficiencia en la ejecución de los proyectos.
                    </TextList>
                    <TextList>Crecimiento empresarial.</TextList>
                    <TextList>
                        Responsabilidad y cumplimiento con nuestros clientes.
                    </TextList>
                    <TextList>Posicionamiento de la empresa.</TextList>
                    <TextList>Mejora continua.</TextList>
                </List>

                <LineDivider />

                <TitleLists>Nuestros principios y valores</TitleLists>
                <List>
                    <TextList>Confidencialidad</TextList>
                    <TextList>Responsabilidad</TextList>
                    <TextList>Cumplimiento</TextList>
                    <TextList>Trabajo en equipo</TextList>
                    <TextList>Liderazgo</TextList>
                    <TextList>Transparencia</TextList>
                    <TextList>Honestidad</TextList>
                </List>
            </SubContainerPoliticsAboutSection>
        </PoliticsAboutSection>
    );
}

const PoliticsAboutSection = styled.section`
    display: flex;
    justify-content: center;
    padding: 5% 0;
    font-family: "Noto Serif", serif;
`;

const SubContainerPoliticsAboutSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    gap: 5vh;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vh;
    }

    @media (max-width: 600px) {
        width: 90%;
        gap: 0;
    }
`;

const TitlePoliticsAbout = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};
    text-align: center;

    @media (max-width: 1250px) {
        font-size: 40px;
        text-align: center;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const LineDivider = styled.hr`
    color: #161616;
    width: 100%;
`;

const TitleLists = styled.h1`
    font-size: 30px;
    font-weight: 800;
    color: ${Color.textDark};
    text-align: center;

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }


    @media (max-width: 600px) {
        font-size: 5vw;
    }

    @media (max-width: 400px) {
        font-size: 6vw;
    }
`;

const DescriptionPoliticsAbout = styled.p`
    font-size: 20px;
    color: ${Color.textDark};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;     
    }

    @media (max-width: 400px) {
        font-size: 5vw;
        line-height: 4vh;
    }
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    padding: 0;
`;

const TextList = styled.li`
    font-size: 20px;
    color: ${Color.textDark};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 15px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 3vw;
    }

    @media (max-width: 400px) {
        font-size: 4vw;
    }
`;
