import TreatmentDataPage from "../TreatmentDataPage";
import AboutPage from "../AboutPage";
import IndexPage from "../IndexPage";
import ServicePage from "../ServicesPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";


const Index = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<IndexPage></IndexPage>}></Route>
                <Route path="/services" element={<ServicePage></ServicePage>}></Route>
                <Route path="/about" element={<AboutPage></AboutPage>}></Route>
                <Route path="/treatmentData" element={<TreatmentDataPage></TreatmentDataPage>}></Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Index;
