import styled from "@emotion/styled";
import * as Color from "../../Theme";
import PhotoMembers from "../../Assets/Images/PhotoMembers.webp";

function AboutUsTeamIndex() {
    return (
        <AboutUsTeamSection>
            <SubContainerAboutUsTeam>
                <AboutUsTeamImage src={PhotoMembers} alt="PhotoMembers" />
                <TextContainer>
                    <TitleAboutUsTeam>
                        Nuestro equipo de trabajo
                    </TitleAboutUsTeam>
                    <DescriptionAboutUsTeam>
                        Contamos con un equipo altamente calificado y
                        experimentado, con sólidas habilidades de liderazgo,
                        dedicado a ofrecer soluciones efectivas a las diversas
                        necesidades empresariales. La combinación de
                        conocimientos profundos en diferentes áreas y la
                        capacidad de liderazgo de nuestro personal nos permite
                        abordar de manera eficiente los desafíos específicos de
                        cada cliente. Nos enorgullece no solo de nuestra
                        experiencia técnica, sino también de nuestra
                        colaboración proactiva para asegurar que superemos las
                        expectativas y proporcionemos soluciones adaptadas a las
                        demandas cambiantes del entorno empresarial.
                    </DescriptionAboutUsTeam>
                </TextContainer>
            </SubContainerAboutUsTeam>
        </AboutUsTeamSection>
    );
}

export default AboutUsTeamIndex;

const AboutUsTeamSection = styled.section`
    display: flex;
    justify-content: center;
    padding: 5% 0;
    background-color: ${Color.primaryColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerAboutUsTeam = styled.div`
    display: flex;
    justify-content: center;
    width: 90%;
    gap: 5%;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vh;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    order: 1;
`;

const TitleAboutUsTeam = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};
    text-align: center;

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const DescriptionAboutUsTeam = styled.p`
    font-size: 18px;
    color: ${Color.textDark};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;     
    }

    @media (max-width: 400px) {
        font-size: 5vw;
        line-height: 4vh;
    } 
`;

const AboutUsTeamImage = styled.img`
    max-width: 700px;
    width: 100%;
    border-radius: 10%;

    @media (max-width: 1500px) {
        max-width: 550px;
    }

    @media (max-width: 1300px) {
        max-width: 45%;
    }

    @media (max-width: 1250px) {
        max-width: 600px;
        order: 2;
    }

    @media (max-width: 750px) {
        max-width: 550px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;
