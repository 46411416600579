import NavBar from "../../Componets/NavBar";
import HeaderIndex from "../../Componets/HeaderIndex";
import AboutUsIndex from "../../Componets/AboutUsIndex";
import AboutUsTeamIndex from "../../Componets/AboutUsTeamIndex";
import OurServicesIndex from "../../Componets/OurServicesIndex";
import OurTeamIndex from "../../Componets/OurTeamIndex";
import Contact from "../../Componets/Contact";
import Footer from "../../Componets/Footer";

function IndexPage() {
    return (
        <main>
            <NavBar />
            <HeaderIndex />
            <AboutUsIndex />
            <AboutUsTeamIndex />
            <OurServicesIndex />
            <OurTeamIndex />
            <Contact />
            <Footer />
        </main>
    );
}

export default IndexPage;