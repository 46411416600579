import styled from "@emotion/styled";
import * as Color from "../../Theme";

function About() {
    return (
        <AboutSection>
            <SubAboutContainer>
                <TextContainer>
                    <MainTitle>Sobre nuestra empresa</MainTitle>
                    <Description>
                        SYRD.SAS, es firma colombiana, con profesionales
                        multidisciplinarios altamente calificados, comprometidos
                        con la excelencia en la prestación de nuestros servicios
                        en las áreas de consultoría, asesoría auditorías en
                        sistemas de Control Interno y Sistemas de Gestión en las
                        normas ISO 9001,14001, 27001, 30401 ,45001,39001 y 55001
                        (gestión de activos), seguridad de procesos y gestión
                        del conocimiento. Además, brindamos asistencia legal y
                        reglamentaria en los ámbitos laborales, ambientales,
                        seguridad vial seguridad y salud en el trabajo y
                        regulatorios.
                    </Description>
                </TextContainer>
            </SubAboutContainer>
        </AboutSection>
    );
}

export default About;

const AboutSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    font-family: "Noto Serif", serif;
`;

const SubAboutContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2vh;

    @media (min-width: 1100px) {
        width: 85%;
    }

    @media (max-width: 1100px) {
        align-items: center;
        flex-direction: column;
        width: 90%;
    }

    @media (max-width: 1250px) {
        align-items: center;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5%;
    width: 60%;

    @media (max-width: 800px) {
        width: 100%;
        gap: 1.5vh;
    }
`;

const MainTitle = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const Description = styled.p`
    font-size: 18px;
    color: ${Color.textDark};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;     
    }

    @media (max-width: 400px) {
        font-size: 5vw;
        line-height: 4vh;
    }
`;