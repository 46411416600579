import styled from "@emotion/styled";
import * as Color from "../../Theme";
import security from "../../Assets/Icons/Security.svg";
import graph from "../../Assets/Icons/Graph.svg";
import hands from "../../Assets/Icons/Hands.svg";

function OurServicesIndexIndex() {
    return (
        <OurServicesIndexSection>
            <SubContainerOurServicesIndex>
                <TitleOurServicesIndex>Cadena de valor</TitleOurServicesIndex>
                <ContainerIcons>
                    {/* <a
                        href="https://www.flaticon.com/free-icons/lock"
                        title="lock icons"
                    >
                        Lock icons created by Vectors Market - Flaticon
                    </a> */}
                    <IndividualContainer>
                        <IconImage src={security} alt="Security Icon" />
                        <SubtitleOurServicesIndex>
                            Seguridad
                        </SubtitleOurServicesIndex>
                    </IndividualContainer>
                    <IndividualContainer>
                        {/* <a
                            href="https://www.flaticon.com/free-icons/growth"
                            title="growth icons"
                        >
                            Growth icons created by Freepik - Flaticon
                        </a> */}
                        <IconImage src={graph} alt="Graph Icon" />
                        <SubtitleOurServicesIndex>
                            Calidad
                        </SubtitleOurServicesIndex>
                    </IndividualContainer>
                    <IndividualContainer>
                        {/* <a
                            href="https://www.flaticon.com/free-icons/trust"
                            title="trust icons"
                        >
                            Trust icons created by Dewi Sari - Flaticon
                        </a> */}
                        <IconImage src={hands} alt="Hands Icon" />
                        <SubtitleOurServicesIndex>
                            Confiabilidad
                        </SubtitleOurServicesIndex>
                    </IndividualContainer>
                </ContainerIcons>
            </SubContainerOurServicesIndex>
        </OurServicesIndexSection>
    );
}

export default OurServicesIndexIndex;

const OurServicesIndexSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5% 0;
    background: ${Color.fifthColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerOurServicesIndex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    gap: 5vh;

    @media (max-width: 1000px) {
        gap: 2vh;
    }
`;

const TitleOurServicesIndex = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textWhite};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const ContainerIcons = styled.div`
    display: flex;
    justify-content: center;
    width: 90%;
    gap: 10%;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

const IndividualContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IconImage = styled.img`
    width: 100%;
    max-width: 150px;

    @media (max-width: 1500px) {
        max-width: 140px;
    }

    @media (max-width: 1300px) {
        max-width: 130px;
    }

    @media (max-width: 1250px) {
        max-width: 150px;
    }

    @media (max-width: 750px) {
        max-width: 100px;
    }

    @media (max-width: 600px) {
        max-width: 30%;
    }
`;

const SubtitleOurServicesIndex = styled.h2`
    font-size: 22px;
    font-weight: 800;
    color: ${Color.textWhite};
    font-family: "Noto Serif", serif;

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;
