import { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "@emotion/styled";
import * as Color from "../../Theme";

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        console.log("Formulario antes del envío:", form.current);

        emailjs
            .sendForm(
                "service_02jpl9z",
                "template_fi59wzo",
                form.current,
                "T607K_U3ridGTf2lp"
            )
            .then(
                (result) => {
                    console.log("Respuesta exitosa:", result.text);
                    console.log("¡Mensaje enviado con éxito!");
                },
                (error) => {
                    console.error(
                        "Error al enviar el correo electrónico:",
                        error.text
                    );
                }
            );
    };

    return (
        <ContactSection>
            <SubContainerContact>
                <TitleContact>Contactanos</TitleContact>
                <ContainerFormRedes>
                    <FormContact ref={form} onSubmit={sendEmail}>
                        <LabelFormContact>Nombre</LabelFormContact>
                        <InputFormContact type="text" name="from_name" required/>
                        <LabelFormContact>Correo Electronico</LabelFormContact>
                        <InputFormContact type="email" name="from_email" required/>
                        <LabelFormContact>Mensaje</LabelFormContact>
                        <TextAreaFormContact name="message" required/>
                        <LabelFormContactBox>
                            <InputCheck
                                type="checkbox"
                                id="cbox1"
                                value="first_checkbox"
                                required
                            />{" "}
                            He leido y aceptado la <a href="/treatmentData">Política  de
                            Tratamiento de Datos</a>  Personales.
                        </LabelFormContactBox>
                        <ButtonSend type="submit" value="Enviar" />
                    </FormContact>
                    <ContainerSocial>
                        <TitleSocial>Redes sociales</TitleSocial>
                        <SubContainerSocial>
                            <SocialNetwork>
                                <Icon className="bi bi-facebook"></Icon>
                                <div>
                                    <TitleSocialNetwork target="_blank" href="https://www.facebook.com/profile.php?id=100054416340121">
                                        Facebook
                                    </TitleSocialNetwork>
                                    <SubtitleSocialNetwork>
                                        @SYRD
                                    </SubtitleSocialNetwork>
                                </div>
                            </SocialNetwork>
                            <SocialNetwork>
                                <Icon className="bi bi-whatsapp"></Icon>
                                <div>
                                    <TitleSocialNetwork target="_blank" href="https://api.whatsapp.com/send?phone=%2B573103425336&data=ARAVvNEa-0J7acKy9T8Y5z7DvG2PJxmjHZvGLA4HSuF2om3mWVTwHrHCsjWicgTCrlgfvbySP3dvhcWziVdERPpvaPrEdPdIysUI7_WBYL718lTCC3czivv9bSvEHHjrT3TdoM6oqBfZAr6DKwwugRWQCg&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR3WcXfw_g5g-_WMp1b9PkDvbje_PSyjWSRi5ujMnBM7Wu2D41Flua6FaT8">
                                        Whatsapp
                                    </TitleSocialNetwork>
                                    <SubtitleSocialNetwork>
                                        @SYRD
                                    </SubtitleSocialNetwork>
                                </div>
                            </SocialNetwork>
                            <SocialNetwork>
                                <Icon className="bi bi-linkedin"></Icon>
                                <div>
                                    <TitleSocialNetwork target="_blank" href="https://www.linkedin.com/in/sandra-liliana-ramos-pe%C3%B1aloza-3556a1106/">
                                        Linkedin
                                    </TitleSocialNetwork>
                                    <SubtitleSocialNetwork>
                                        @SYRD
                                    </SubtitleSocialNetwork>
                                </div>
                            </SocialNetwork>
                        </SubContainerSocial>
                    </ContainerSocial>
                </ContainerFormRedes>
            </SubContainerContact>
        </ContactSection>
    );
};

export default Contact;

const ContactSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10%;
    padding-bottom: 10%;
    gap: 10vh;
    background: ${Color.primaryColor};
`;

const SubContainerContact = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    gap: 5vh;

    @media (max-width: 1000px) {
        gap: 2vh;
    }
`;

const TitleContact = styled.h1`
    font-size: 70px;
    font-weight: 800;
    margin-bottom: 5vh;

    @media (max-width: 1250px) {
        font-size: 60px;
    }

    @media (max-width: 1000px) {
        font-size: 40px;
    }

    @media (max-width: 750px) {
        font-size: 45px;
    }

    @media (max-width: 550px) {
        font-size: 10vw;
    }

    @media (max-width: 250px) {
        font-size: 11vw;
    }
`;

const ContainerFormRedes = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;

    @media (max-width: 1250px) {
        flex-direction: column-reverse;
        gap: 2vh;
    }
`;

const FormContact = styled.form`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;

    @media (max-width: 1250px) {
        width: 600px;
    }

    @media (max-width: 750px) {
        width: 550px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const LabelFormContact = styled.label`
    font-size: 22px;

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const InputFormContact = styled.input`
    width: 100%;
    padding: 1%;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
        border: 1px solid ${Color.fifthColor};
    }
`;

const TextAreaFormContact = styled.textarea`
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    outline: none;
    resize: none;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
        border: 1px solid ${Color.fifthColor};
    }
`;

const InputCheck = styled.input`
    width: 18px;
    height: 18px;

    @media (max-width: 400px) {
        width: 5vw;
        height: 5vw;
    }
`;

const LabelFormContactBox = styled.label`
    font-size: 18px;
    margin: 2% 0;

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const ButtonSend = styled.input`
    margin-top: 2vh;
    padding: 1% 20%;
    border-radius: 10px;
    cursor: pointer;
    background: ${Color.fifthColor};
    color: ${Color.textWhite};
    border: none;

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const ContainerSocial = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3% 5%;
    border-radius: 5%;
    background: ${Color.secondaryColor};

    @media (max-width: 1250px) {
        width: 600px;
    }

    @media (max-width: 750px) {
        width: 550px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const TitleSocial = styled.h1`
    font-weight: 800;
    font-size: 50px;
    text-align: center;

    @media (max-width: 1250px) {
        font-size: 45px;
    }

    @media (max-width: 1000px) {
        font-size: 40px;
    }

    @media (max-width: 750px) {
        font-size: 35px;
    }

    @media (max-width: 600px) {
        font-size: 7vw;
    }

    @media (max-width: 400px) {
        font-size: 8vw;
    }
`;

const SubContainerSocial = styled.div`
    display: flex;
    flex-direction: column;
`;

const SocialNetwork = styled.div`
    display: flex;
    align-items: center;
    padding-left: 5%;
`;

const Icon = styled.i`
    font-size: 60px;
    margin-right: 5%;
    color: #000;

    @media (max-width: 750px) {
        font-size: 50px;
    }

    @media (max-width: 600px) {
        font-size: 10vw;
    }
`;

const TitleSocialNetwork = styled.a`
    font-size: 30px;
    text-decoration: none;
    color: ${Color.textDark};

    @media (max-width: 750px) {
        font-size: 25px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const SubtitleSocialNetwork = styled.h2`
    font-size: 25px;

    @media (max-width: 750px) {
        font-size: 20px;
    }

    @media (max-width: 600px) {
        font-size: 3vw;
    }

    @media (max-width: 400px) {
        font-size: 4vw;
    }
`;
