import styled from "@emotion/styled";
import * as Color from "../../Theme";
import Illustration1 from "../../Assets/Images/LegalConsulting.webp";
import Illustration2 from "../../Assets/Images/ProcessSecurity.webp";
import Illustration3 from "../../Assets/Images/IngSoftware.webp";
import Illustration4 from "../../Assets/Images/KnowledgeCenter.webp";
import Illustration5 from "../../Assets/Images/SustainableDevelopment.webp";
import Illustration6 from "../../Assets/Images/ManagementAndAuditoriums.webp";

function ServicesGalleryServices() {
    return (
        <ServicesGalleryServicesSection>
            <SubContainerServicesSection>
                <TitleServicesGalleryServices>
                    Nuestras soluciones
                </TitleServicesGalleryServices>

                <ImageContainer>
                    <SubImageContainer>
                        <ContainerImage>
                            <ServicesGalleryServicesImage
                                src={Illustration1}
                                alt="CONSULTORIAS LEGALES"
                            />
                            <TitleImage>CONSULTORIAS LEGALES</TitleImage>
                        </ContainerImage>

                        <ContainerImage>
                            <ServicesGalleryServicesImage
                                src={Illustration2}
                                alt="SEGURIDAD DE PROCESOS"
                            />
                            <TitleImage>SEGURIDAD DE PROCESOS</TitleImage>
                        </ContainerImage>

                        <ContainerImage>
                            <ServicesGalleryServicesImage
                                src={Illustration3}
                                alt="INGENIERIA DE SOFTWARE"
                            />
                            <TitleImage>INGENIERIA DE SOFTWARE</TitleImage>
                        </ContainerImage>
                    </SubImageContainer>
                    <SubImageContainer>
                        <ContainerImage>
                            <ServicesGalleryServicesImage
                                src={Illustration4}
                                alt="CENTRO DE CONOCIMIENTO"
                            />
                            <TitleImage>CENTRO DE CONOCIMIENTO</TitleImage>
                        </ContainerImage>

                        <ContainerImage>
                            <ServicesGalleryServicesImage
                                src={Illustration5}
                                alt="DESARROLLO SOSTENIBLE"
                            />
                            <TitleImage>DESARROLLO SOSTENIBLE</TitleImage>
                        </ContainerImage>

                        <ContainerImage>
                            <ServicesGalleryServicesImage
                                src={Illustration6}
                                alt="AUDITORIAS"
                            />
                            <TitleImage>GESTION Y AUDITORIAS</TitleImage>
                        </ContainerImage>
                    </SubImageContainer>
                </ImageContainer>
            </SubContainerServicesSection>
        </ServicesGalleryServicesSection>
    );
}

export default ServicesGalleryServices;

const ServicesGalleryServicesSection = styled.section`
    display: flex;
    padding: 5% 0;
    justify-content: center;
    font-family: "Noto Serif", serif;
    background-color: ${Color.sixthcolor};
`;

const SubContainerServicesSection = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5vh;
`;

const TitleServicesGalleryServices = styled.h1`
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
`;

const SubImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vh;

    @media (max-width: 800px) {
        display: grid;
        grid-template-columns: repeat(1, 80vw);
    }
`;

const ContainerImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5vh; 

    @media (max-width: 800px) {
        gap: 1.5vh;
    }
`;

const ServicesGalleryServicesImage = styled.img`
    width: 100%;
    max-width: 350px;
    border-radius: 10%;

    @media (max-width: 1500px) {
        max-width: 350px;
    }

    @media (max-width: 1300px) {
        max-width: 300px;
    }

    @media (max-width: 1250px) {
        max-width: 350px;
    }

    @media (max-width: 750px) {
        max-width: 250px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

const TitleImage = styled.h2`
    font-size: 25px;
    font-weight: 700;
    color: ${Color.textDark};
    text-align: center;

    @media (max-width: 1100px) {
        font-size: 20px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 500px) {
        font-size: 5vw;
    }

    @media (max-width: 400px) {
        font-size: 6vw;
    }
`;
