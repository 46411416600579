import styled from "@emotion/styled";
import * as Color from "../../Theme";

export default function TreatmentData() {
    return (
        <AboutIndexSection>
            <SubContainerAboutIndex>
                <TitleTreatmentData>Tratamiento de datos</TitleTreatmentData>
                <DescriptionTreatmentData>
                    Al diligenciar este formulario usted autoriza el uso de
                    datos personales conforme a lo establecido en la
                    normatividad vigente en Colombia; por lo tanto, otorgo mi
                    consentimiento previo, expreso e informado, a <b>SYRD S.A.S</b> ;
                    para la recolección, almacenamiento, uso, circulación,
                    transferencia o supresión de los datos personales; la
                    información suministrada y toda aquella información
                    adicional a la cual llegare a tener acceso en desarrollo de
                    la relación bien sea comercial, contractual o legal. Declaro
                    que en cumplimiento en la normatividad de protección de
                    datos personales, fui informado de las finalidades, y usos
                    que tendrán mis datos personales, derechos y canales de
                    comunicación: <br></br><br></br>Para fines comerciales, de atención al
                    cliente, mercadeo y actualización; es decir, para todas
                    aquellas actividades asociadas a la relación comercial o
                    vinculo existente con la empresa. <br></br>Así mismo, declaro que
                    <b> SYRD SAS</b>, me dio a conocer las finalidades para las cuales
                    serán tratados mis datos personales, dando claridad y mayor
                    énfasis en mi libertad de suministrar o no y por tanto
                    autorizar. <br></br>Otorgo mi consentimiento para tratar mi
                    información personal de acuerdo con la política de
                    tratamiento de datos personales de <b>SYRD SAS</b> <br></br>En caso que
                    Usted no desee que sus datos personales sean utilizados por 
                    <b> SYRD SAS</b>, podrá revocar de manera parcial o total tal
                    autorización de manera expresa e inequívoca, directa,
                    expresa y por escrito que permita concluir de forma
                    razonable que se revoca tal autorización o consentimiento.
                    Datos de contacto: syrd.sas@gmail.com
                </DescriptionTreatmentData>
            </SubContainerAboutIndex>
        </AboutIndexSection>
    );
}

const AboutIndexSection = styled.section`
    display: flex;
    justify-content: center;
    padding: 5% 0;
    background-color: ${Color.primaryColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerAboutIndex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    gap: 5vh;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vh;
    }
`;

const TitleTreatmentData = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};
    text-align: center;

    @media (max-width: 1250px) {
        font-size: 40px;
        text-align: center;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const DescriptionTreatmentData = styled.p`
    font-size: 20px;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 15px;
        text-align: center;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;
