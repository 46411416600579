import styled from "@emotion/styled";
import * as Color from "../../Theme";
import Illustration1 from "../../Assets/Images/PublicServices.webp";
import Illustration2 from "../../Assets/Images/Transport.webp";
import Illustration3 from "../../Assets/Images/EngineeringCompany.webp";

function ClientsAbout() {
    return (
        <ClientsAboutSection>
            <SubContainerServicesSection>
                <div>
                    <TitleClientsAbout>Clientes potenciales</TitleClientsAbout>
                    <SubtitleClientsAbout>
                        Nuestra soluciones están enfocadas en los siguientes
                        sectores
                    </SubtitleClientsAbout>
                </div>
                <ImageContainer>
                    <SubImageContainer>
                        <ContainerImage>
                            <ClientsAboutImage
                                src={Illustration1}
                                alt="Image of Our Team"
                            />
                            <TitleImage>Servicios publicos</TitleImage>
                        </ContainerImage>

                        <ContainerImage>
                            <ClientsAboutImage
                                src={Illustration2}
                                alt="Image of Our Team"
                            />
                            <TitleImage>Empresa de transporte</TitleImage>
                        </ContainerImage>

                        <ContainerImage>
                            <ClientsAboutImage
                                src={Illustration3}
                                alt="Image of Our Team"
                            />
                            <TitleImage>Empresas de ingenieria</TitleImage>
                        </ContainerImage>
                    </SubImageContainer>
                </ImageContainer>
            </SubContainerServicesSection>
        </ClientsAboutSection>
    );
}

export default ClientsAbout;

const ClientsAboutSection = styled.section`
    display: flex;
    padding: 5% 0;
    justify-content: center;
    background-color: ${Color.primaryColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerServicesSection = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5vh;
`;

const TitleClientsAbout = styled.h1`
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const SubtitleClientsAbout = styled.h2`
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 5vw;
    }

    @media (max-width: 400px) {
        font-size: 6vw;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
`;

const SubImageContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5vh;

    @media (max-width: 500px) {
        display: grid;
        grid-template-columns: repeat(1, 80vw);
    }
`;

const ContainerImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;

    @media (max-width: 800px) {
        gap: 1.5vh;
    }
`;

const ClientsAboutImage = styled.img`
    width: 100%;
    max-width: 350px;
    border-radius: 10%;

    @media (max-width: 1500px) {
        max-width: 350px;
    }

    @media (max-width: 1300px) {
        max-width: 300px;
    }

    @media (max-width: 1250px) {
        max-width: 350px;
    }

    @media (max-width: 500px) {
        max-width: 350px;
    }

    @media (max-width: 750px) {
        max-width: 250px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

const TitleImage = styled.h2`
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 600px) {
        font-size: 3vw;
    }

    @media (max-width: 500px) {
        font-size: 7vw;
    }

    @media (max-width: 400px) {
        font-size: 8vw;
    }
`;
