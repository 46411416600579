import styled from "@emotion/styled";
import * as Color from "../../Theme";
import imageMisionAbout from "../../Assets/Images/MisionAndVision.webp";

function MisionAndVisionAbout() {
    return (
        <MisionAndVisionSection>
            <SubContainerMisionAndVision>
            <MisionAboutImage
                    src={imageMisionAbout}
                    alt="MisionAbout Image"
                />

                <ContainerMisionAndVision>
                <TextContainer>
                    <TitleMisionAndVision>
                        Nuestra misión 
                    </TitleMisionAndVision>
                    <DescriptionMisionAndVision>
                        Superar las expectativas de nuestros clientes en el
                        desarrollo de proyectos relacionados con asesorías,
                        auditorías y consultorías especializadas para el diseño
                        e implementación de normas internacionales (ISO),
                        gestión del riesgo y requisitos legales; nuestra
                        filosofía está enmarcada en la responsabilidad y la
                        mejora continua para la generación de valor.
                    </DescriptionMisionAndVision>
                </TextContainer>
                <LineDivider />
                <TextContainer>
                    <TitleMisionAndVision>
                    Nuestra visión
                    </TitleMisionAndVision>
                    <DescriptionMisionAndVision>
                    Lograr el reconocimiento de nuestra firma, mediante la
                        implementación de soluciones empresarial personalizadas
                        asegurando la excelencia y calidad en el desarrollo de
                        nuestros proyectos de consultorías, asesorías y
                        auditorías.
                    </DescriptionMisionAndVision>
                </TextContainer>
                </ContainerMisionAndVision>
            </SubContainerMisionAndVision>
        </MisionAndVisionSection>
    );
}

export default MisionAndVisionAbout;

const MisionAndVisionSection = styled.section`
    display: flex;
    justify-content: center;
    padding: 5% 0;
    font-family: "Noto Serif", serif;
    background: ${Color.primaryColor};
`;

const SubContainerMisionAndVision = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 5%;
    gap: 5%;

    @media (max-width: 1250px) {
        flex-direction: column;
        gap: 2vh;
    }
`;

const MisionAboutImage = styled.img`
    max-width: 700px;
    width: 100%;

    @media (max-width: 1500px) {
        max-width: 550px;
    }

    @media (max-width: 1300px) {
        max-width: 45%;
    }

    @media (max-width: 1250px) {
        max-width: 600px;
    }

    @media (max-width: 750px) {
        max-width: 550px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`

const LineDivider = styled.hr`
    color: #161616;
    width: 100%;
`;

const ContainerMisionAndVision = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5vh;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vh;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
`;

const TitleMisionAndVision = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 40px;
        text-align: center;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const DescriptionMisionAndVision = styled.p`
    font-size: 18px;
    color: ${Color.textDark};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;     
    }

    @media (max-width: 400px) {
        font-size: 5vw;
        line-height: 4vh;
    }
`;


