import styled from "@emotion/styled";
import * as Color from "../../Theme";
import Video from "../../Assets/Video/About.mp4"

function  AboutUsIndex() {
    return (
        <AboutIndexSection>
            <SubContainerAboutIndex>
                <TextContainer>
                    <TitleAboutIndex>¿Quienes somos?</TitleAboutIndex>
                    <DescriptionAboutIndex>
                        Somos una empresa colombiana, con profesionales
                        multidisciplinarios altamente calificados, comprometidos
                        con la excelencia en la prestación de nuestros servicios
                        profesionales especializados, en las áreas de:
                        consultoría,asesoría,auditorías legales y de gestion en
                        sistemas de Control Interno y Sistemas de Gestión en las
                        normas ISO 9001,14001, 27001, 30401 ,45001,39001 y 55001
                        (gestión de activos), seguridad de procesos e
                        ingeniería.
                    </DescriptionAboutIndex>
                    <LearnMoreButton href="/services">
                        Nuestros Servicios
                        <ButtonIcon className="bi bi-arrow-right-short"></ButtonIcon>
                    </LearnMoreButton>
                </TextContainer>
                <AboutIndexVideo autoPlay loop muted >
                    <source src={Video} type="video/mp4" />
                    Tu navegador no soporta el tag de video.
                </AboutIndexVideo>
            </SubContainerAboutIndex>
        </AboutIndexSection>
    );
}

export default AboutUsIndex;

const AboutIndexSection = styled.section`
    display: flex;
    justify-content: center;
    padding: 5% 0;
    background-color: ${Color.primaryColor};
    font-family: "Noto Serif", serif;
`;

const SubContainerAboutIndex = styled.div`
    display: flex;
    justify-content: center;
    width: 90%;
    gap: 5%;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vh;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
`;

const TitleAboutIndex = styled.h1`
    font-size: 50px;
    font-weight: 800;
    color: ${Color.textDark};
    text-align: center;

    @media (max-width: 1250px) {
        font-size: 40px;
    }

    @media (max-width: 1000px) {
        font-size: 35px;
    }

    @media (max-width: 750px) {
        font-size: 30px;
    }

    @media (max-width: 600px) {
        font-size: 8vw;
    }

    @media (max-width: 400px) {
        font-size: 9vw;
    }
`;

const DescriptionAboutIndex = styled.p`
    font-size: 18px;
    color: ${Color.textDark};
    line-height: 2rem;

    @media (max-width: 1250px) {
        font-size: 17px;
    }

    @media (max-width: 1000px) {
        font-size: 18px;
    }

    @media (max-width: 750px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;     
    }

    @media (max-width: 400px) {
        font-size: 5vw;
        line-height: 4vh;
    }
`;

const LearnMoreButton = styled.a`
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 900;
    border: none;
    cursor: pointer;
    gap: 1rem;
    text-decoration-line: none;
    color: ${Color.textDark};
    background-color: transparent;

    @media (max-width: 1250px) {
        font-size: 25px;
    }

    @media (max-width: 1000px) {
        font-size: 20px;
    }

    @media (max-width: 750px) {
        font-size: 15px;
    }

    @media (max-width: 600px) {
        font-size: 4vw;
    }

    @media (max-width: 400px) {
        font-size: 5vw;
    }
`;

const ButtonIcon = styled.i`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: ${Color.quaternaryColor};

    @media (max-width: 500px) {
        font-size: 8vw;
        width: 8vw;
        height: 8vw;
    }
`;

const AboutIndexVideo = styled.video`
    max-width: 700px;
    width: 100%;
    border-radius: 10%;

    @media (max-width: 1500px) {
        max-width: 550px;
    }

    @media (max-width: 1300px) {
        max-width: 45%;
    }

    @media (max-width: 1250px) {
        max-width: 600px;
    }

    @media (max-width: 750px) {
        max-width: 550px;
    }

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;
