import styled from "@emotion/styled";
import * as Color from "../../Theme";
import { useEffect, useState } from "react";

function HeaderIndex() {
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [currentPhrase, setCurrentPhrase] = useState("");
    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {
        const titlePhrases = [
            "SYRD S.A.S",
            "Trabajamos con excelencia y calidad.",
            "Superamos sus expectativas.",
            "Nuestro compromiso es lograr sus objetivos"
        ];

        if (isTyping) {
            const phraseTimeout = setTimeout(() => {
                if (currentPhrase.length < titlePhrases[currentPhraseIndex].length) {
                    const nextCharIndex = currentPhrase.length;
                    setCurrentPhrase(
                        titlePhrases[currentPhraseIndex].substring(0, nextCharIndex + 1)
                    );
                } else {
                    setIsTyping(false);
                    setTimeout(() => {
                        setCurrentPhrase("");
                        setCurrentPhraseIndex(
                            (prevIndex) => (prevIndex + 1) % titlePhrases.length
                        );
                        setIsTyping(true);
                    }, 3000); 
                }
            }, 50);

            return () => clearTimeout(phraseTimeout);
        }
    }, [currentPhrase, currentPhraseIndex, isTyping]);

    return (
        <HeaderContainer>
            <SubContainerHeader>
                <TitleHeader>{currentPhrase}</TitleHeader>
            </SubContainerHeader>
        </HeaderContainer>
    );
};

export default HeaderIndex;

const HeaderContainer = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh; 
    padding: 5%; 
    font-family: 'Noto Serif', serif;
    background-color: ${Color.secondaryColor};

    @media (max-width: 1250px) {
        min-height: 20px;
    }

    @media (max-width: 1000px) {
        min-height: 15vh; 
    }
`;

const SubContainerHeader = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
`;

const TitleHeader = styled.h1`
    font-size: 70px;
    color: ${Color.textDark};

    @media (max-width: 1250px) {
        font-size: 60px;
    }

    @media (max-width: 1000px) {
        font-size: 40px;
    }

    @media (max-width: 750px) {
        font-size: 45px;
    }

    @media (max-width: 550px) {
        font-size: 7vw;
    }

    @media (max-width: 250px) {
        font-size: 9vw;
    }
`;
